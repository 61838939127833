import React from "react";
import { HiCheck } from "react-icons/hi";
import Swal from "sweetalert2";

import useSWRMutation from "swr/mutation";
import { sendRequestPut } from "../../swr/axiosFetch";
import { API_URL_TES } from "../../config";
import LoadingCheck from "./LoadingCheck";

const QuestTextOpsi = React.memo(
  ({ noOpsi, opsiId, txtOpsi, jawabId, masterId, questionId, mutate }) => {
    const { trigger, isMutating } = useSWRMutation(
      `${API_URL_TES}/pemohon/simpanjawaban`,
      sendRequestPut,
      {
        loadingTimeout: 3000,
        onSuccess() {
          mutate();
        },
      }
    );

    const simpan = async (e) => {
      e.preventDefault();

      const jawabRes = await trigger({
        masterId: masterId,
        questionId: questionId,
        noJawab: noOpsi,
        jawabId: opsiId,
      });

      if (jawabRes.status === 200) {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });

        Toast.fire({
          icon: "success",
          title: jawabRes.data.message,
        });
      } else {
        Swal.fire(
          "ERROR!",
          "Terjadi kesalaha, mohon periksa internet Anda, silahkan ulangi kembali!",
          "error"
        );
      }
    };

    return (
      <button
        type="button"
        disabled={isMutating || opsiId === jawabId ? true : false}
        onClick={simpan}
        className={
          opsiId === jawabId
            ? "flex flex-row items-center bg-white p-4 shadow-lg w-full rounded-lg mb-2 border-2 border-blue-500"
            : "flex flex-row items-center bg-white p-4 shadow-lg w-full rounded-lg mb-2 border-2 border-gray-50 hove:border-blue-500"
        }
      >
        {isMutating ? (
          <LoadingCheck />
        ) : (
          <>
            {opsiId === jawabId ? (
              <span className="md:w-6 md:h-6 w-4 h-4 rounded-full bg-blue-500 shadow-lg mr-4 flex items-center justify-center">
                <HiCheck color="#FFFFFF" size={24} />
              </span>
            ) : (
              <span className="md:w-6 md:h-6 w-4 h-4 rounded-full bg-gray-300 mr-4 flex items-center justify-center">
                <HiCheck color="#FFFFFF" size={24} />
              </span>
            )}
          </>
        )}

        <span className="font-poppins-regular text-sm">{txtOpsi}</span>
      </button>
    );
  }
);

export default QuestTextOpsi;
