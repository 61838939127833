import React from "react";

const InputSelect2 = ({ label, name, id, value, setValue, options, errors, idx }) => {
  const validate = errors.find((element) => element.path === id);

  return (
    <div className="flex gap-2 w-full items-center">
      <label className="block mb-2 text-xs font-poppins-semibold w-[45px]">
        {label}
      </label>
      <div className="w-full">
        <select
          name={name}
          id={id}
          value={value}
          required
          onChange={(e) => setValue(e.target.value, idx)}
          className={
            validate
              ? "bg-[#fee2e2] text-sm rounded-md block w-full p-2.5 focus:ring-red-500 focus:border-red-500 border-[#f87171] placeholder:text-[#f87171] font-poppins-regular focus:bg-[#f9fafb]"
              : "bg-white text-sm rounded-md block w-full p-2.5 focus:ring-blue-500 focus:border-blue-500 border-[#E5E7EB] placeholder:text-[#9CA3AF] font-poppins-regular focus:bg-[#f9fafb]"
          }
        >
          <option value="">- Silahkan Pilih -</option>
          {options.map((val, index) => {
            return (
              <option value={val.value} key={index}>
                {val.name}
              </option>
            );
          })}
        </select>
        {validate && (
          <p className="text-red-500 font-poppins-regular text-xs mt-1">
            * {validate.msg}
          </p>
        )}
      </div>
    </div>
  );
};

export default InputSelect2;
