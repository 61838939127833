import React from "react";
import { useParams } from "react-router-dom";

import Docpdf from "./Docpdf";
import { PDFViewer } from "@react-pdf/renderer";

import useSWR from "swr";
import { API_URL_TES } from "../../config";
import Loading from "../Loading";

const fetcher = (url) => fetch(url).then((r) => r.json());

const ViewPdf = () => {
  const { id } = useParams();

  const { data, error, isLoading } = useSWR(
    `${API_URL_TES}/pemohon/detail/${id}`,
    fetcher,
    {
      loadingTimeout: 3000,
    }
  );

  if (isLoading || error) return <Loading />;

  return (
    <section className="container mx-auto mt-[5.8rem] lg:mt-24">
      <div className="grid grid-cols-12 grid-flow-col gap-3">
        <div className="lg:col-start-4 lg:col-span-6 col-start-1 col-span-12 bg-white p-4 rounded-lg shadow-lg mx-4 lg:mx-0">
          <PDFViewer className="lg:h-[560px] max-sm:h-[380px] w-full">
            <Docpdf data={data} />
          </PDFViewer>
        </div>
      </div>
    </section>
  );
};

export default ViewPdf;
