import React from "react";
import { HiBan } from "react-icons/hi";

import Countdown from "react-countdown";

const ModalCountdown = ({ title, content, icon, url }) => {
  const renderer = ({ seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return "0";
    } else {
      // Render a countdown
      return <span className="font-bold text-red-600 ml-2">{seconds}</span>;
    }
  };

  return (
    <div
      className="relative z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto mt-28">
        <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
          <div className="relative transform overflow-x-hidden overflow-y-auto rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start">
                {icon ? (
                  <>{icon}</>
                ) : (
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10 text-red-500">
                    <HiBan size={24} />
                  </div>
                )}

                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                  <h3
                    className="text-base font-semibold leading-6 text-gray-900"
                    id="modal-title"
                  >
                    {title}
                  </h3>
                  <div className="mt-2">
                    {content}, notif ini akan berakhir dalam:
                    <Countdown
                      date={Date.now() + 3000}
                      renderer={renderer}
                      onComplete={url}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalCountdown;
