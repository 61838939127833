import React from "react";
import { NavLink } from "react-router-dom";
import { Outlet } from "react-router-dom";

const CariHasil = () => {
  return (
    <>
      <div className="container mx-auto py-10 mt-10 lg:mt-14">
        <div className="grid grid-cols-12 grid-flow-col gap-3">
          <div className="lg:col-start-4 lg:col-span-6 col-start-1 col-span-12 bg-white p-4 rounded-t-lg shadow-lg">
            <p className="font-poppins-semibold text-gray-700 text-sm">
              Silahkan pilih jenis tes Anda dibawah ini:
            </p>
            <div className="flex flex-row gap-4 mt-2">
              <NavLink
                to="tesonline"
                className={({ isActive }) =>
                  isActive
                    ? "px-8 py-2 shadow-lg bg-blue-600 text-white font-poppins-semibold border border-1 border-gray-200 rounded-full"
                    : "px-8 py-2 shadow-lg bg-gray-100 text-gray-700 font-poppins-semibold border border-1 border-gray-200 rounded-full"
                }
              >
                Tes Online
              </NavLink>
              <NavLink
                to="tesoffline"
                className={({ isActive }) =>
                  isActive
                    ? "px-8 py-2 shadow-lg bg-blue-600 text-white font-poppins-semibold border border-1 border-gray-200 rounded-full"
                    : "px-8 py-2 shadow-lg bg-gray-100 text-gray-700 font-poppins-semibold border border-1 border-gray-200 rounded-full"
                }
              >
                Tes Offline
              </NavLink>
            </div>
          </div>
        </div>

        <Outlet />
      </div>
    </>
  );
};

export default CariHasil;
