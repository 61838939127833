import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import useSWR from "swr";
import { API_URL_PAY } from "../../config";
import Loading from "../Loading";
import { HiCheck } from "react-icons/hi";
import ErrorPage from "./ErrorPage";
import { sendReqGet } from "../../swr";

const QrcodeStamp = () => {
  const { id } = useParams();

  const { data, error, isLoading } = useSWR(
    `${API_URL_PAY}/pemohon/qrcode/${id}`,
    sendReqGet,
    {
      loadingTimeout: 3000,
    }
  );

  useEffect(() => {
    localStorage.removeItem("tokenTes");
  }, []);

  if (isLoading) return <Loading />;

  if (error) return <ErrorPage />;

  return (
    <div className="container mx-auto mt-[5.8rem] lg:mt-24">
      <div className="grid grid-cols-12 grid-flow-col gap-3">
        <div className="lg:col-start-4 lg:col-span-6 col-start-1 col-span-12 bg-white p-4 rounded-lg shadow-lg mx-4 lg:mx-0">
          <div className="flex justify-center mt-4">
            <div className="relative flex h-10 w-10">
              <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-emerald-400 opacity-75"></span>
              <span className="relative inline-flex rounded-full h-10 w-10 bg-emerald-500 justify-center items-center">
                <HiCheck size={36} color="#FFFFFF" />
              </span>
            </div>
          </div>
          <h2 className="text-center font-poppins-semibold text-2xl text-emerald-600 mt-4 mb-8">
            Data Ter-Verifikasi
          </h2>

          <div className="grid grid-cols-5 text-xs font-poppins-regular gap-y-1 lg:mx-4 mb-4">
            <div className="col-span-2">NIK</div>
            <div className="col-span-3">{data.nik}</div>

            <div className="col-start-1 col-span-2">NAMA LENGKAP</div>
            <div className="col-span-3 font-poppins-semibold">
              {data.namaPemohon}
            </div>

            <div className="col-start-1 col-span-2">TEMPAT, TGL. LAHIR</div>
            <div className="col-span-3">
              {data.tmpLahir}, {data.tglLahir}
            </div>

            <div className="col-start-1 col-span-2">JEN. KELAMIN</div>
            <div className="col-span-3">{data.jenKel}</div>

            <div className="col-start-1 col-span-2">NO. HP</div>
            <div className="col-span-3">{data.noHp}</div>

            <div className="col-start-1 col-span-2">ALAMAT</div>
            <div className="col-span-3">{data.alamat}</div>
          </div>

          <p className="text-center font-poppins text-xs text-gray-400">
            REKOMENDASI
          </p>
          {data.hasilTes === "MS" ? (
            <h2 className="text-center font-poppins-semibold text-2xl text-emerald-600">
              MEMENUHI SYARAT
            </h2>
          ) : (
            <h2 className="text-center font-poppins-semibold text-2xl text-rose-600">
              BELUM MEMENUHI SYARAT
            </h2>
          )}

          <p className="font-poppins-semibold text-lg text-black text-center">
            SIM {data.katSim} {data.jenSim.toUpperCase()}
          </p>
          <div>
            <div className="flex flex-row items-center justify-center mt-4">
              <div
                className="w-36 h-36 border border-black p-2"
                dangerouslySetInnerHTML={{ __html: data.qrcode }}
              />
            </div>
            <p className="text-center mt-4 text-xs text-gray-500 font-poppins-regular">
              Hasil tes berlaku selama 6 bulan sampai dengan: {data.tglExpired}.
              Telah di verifikasi di: {data.namaOutlet}, Oleh: {data.namaUser}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QrcodeStamp;
