import React, { useRef } from "react";
import Countdown, { zeroPad } from "react-countdown";

import { HiArrowRight, HiOutlineClock } from "react-icons/hi";
import Loading from "../../components/Loading";
import moment from "moment-timezone";

const Instruction = React.memo(
  ({
    _id,
    isMutating,
    isMutatingInstruction,
    isLoading,
    instruction,
    skipInstruction,
  }) => {
    // const tglNow = Date.now();
    // const result = new Date(moment.tz(tglNow, "Asia/Jakarta")).getTime();

    const tglFinish = localStorage.getItem("tglSelesaiInstruction");
    // const durasi = Math.floor((tglFinish - result) / 1000);
    // const durasi2 = durasi * 1000;
    // const durasi3 = Date.now() + durasi2;

    // durasi
    const tglFirst = moment();
    const tglSecond = moment(new Date(tglFinish));

    const durasi = tglSecond.diff(tglFirst, "seconds");
    const durasi2 = parseInt(durasi) * 1000;
    const durasi3 = Date.now() + parseInt(durasi2);

    // console.log(`instruksi:${durasi}`);

    const buttonSkip = useRef(null);

    const renderer = ({ minutes, seconds, completed }) => {
      if (completed) {
        // Render a completed state
        return "00:00";
      } else {
        // Render a countdown
        return (
          <span>
            {zeroPad(minutes)}:{zeroPad(seconds)}
          </span>
        );
      }
    };

    if (durasi <= -10) {
      window.location.reload(false);
    } else if (durasi <= 0 && durasi > -9) {
      return <Loading />;
    }

    if (isMutating || isMutatingInstruction || isLoading || isNaN(durasi3))
      return <Loading />;

    return (
      <>
        <div className="lg:px-10 px-2 w-full mb-4 fixed top-20 z-50">
          <div className="flex flex-row items-center bg-white lg:p-4 p-2 shadow-lg rounded-lg">
            <HiOutlineClock size={32} color="#94a3b8" />
            <div className="flex-1 ml-2">
              <p className="font-poppins-semibold text-sm">WAKTU BACA!</p>
              <p className="font-poppins-regular text-xs text-rose-400">
                Perhatikan petunjuk pengerjaan soal dibawah ini!
              </p>
            </div>
            <div className="flex flex-row items-center justify-center font-poppins-semibold">
              <Countdown
                key={_id}
                date={durasi3}
                intervalDelay={0}
                overtime={false}
                precision={3}
                renderer={renderer}
                onComplete={() => buttonSkip.current?.click()}
              />
            </div>
          </div>
        </div>
        <div className="container mx-auto mt-[10rem] lg:mt-44">
          <div className="grid grid-cols-12 grid-flow-col gap-3">
            <div className="lg:col-start-4 lg:col-span-6 col-start-1 col-span-12 bg-white p-4 rounded-lg shadow-lg mx-4 lg:mx-0">
              <h2 className="text-center font-poppins-semibold text-2xl text-red-600">
                Petunjuk!
              </h2>
              <p className="font-poppins-regular text-center text-sm mb-4 text-gray-400">
                Berikut petunjuk cara mengerjakan soal:
              </p>

              <div
                className="flex flex-col space-y-5 mt-5 font-pt-serif text-sm text-black article"
                dangerouslySetInnerHTML={{ __html: instruction }}
              />

              <button
                type="button"
                ref={buttonSkip}
                onClick={skipInstruction}
                disabled={isMutatingInstruction}
                className="text-white bg-[#2563eb] hover:bg-[#2563eb]/90 active::bg-[#2563eb]/50  focus:outline-none focus:ring-[#2563eb]/50 font-poppins-medium rounded-lg text-sm px-5 py-3 text-center inline-flex items-center justify-center shadow-md w-full my-8"
              >
                <span>Skip Petunjuk</span>
                <span className="ml-2">
                  <HiArrowRight />
                </span>
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }
);

export default Instruction;
