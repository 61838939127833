import React, { useEffect, useState } from "react";

import {
  HiArrowRight,
  HiBan,
  HiCheckCircle,
  HiShieldExclamation,
} from "react-icons/hi";

import { useNavigate } from "react-router-dom";
import useSWRMutation from "swr/mutation";
import { sendRequestPost } from "../../swr/axiosFetch";

import InputText from "../../components/Form/InputText";
import InputDate from "../../components/Form/InputDate";
import InputSelect from "../../components/Form/InputSelect";
import InputNumber from "../../components/Form/InputNumber";
import CardSim from "../../components/CardSim";
import { API_URL_TES } from "../../config";
import Policy from "../../components/Policy";
import Loading from "../../components/Loading";
import ModalAll from "../../components/Modal/ModalAll";
import ModalCountdown from "../../components/Modal/ModalCountdown";
import InputSelect2 from "../../components/Form/InputSelect2";

const Daftar = () => {
  const navigate = useNavigate();

  const [katSim, setKatSim] = useState("");
  const [nik, setNik] = useState("");
  const [nikConfirm, setNikConfirm] = useState("");
  const [namaLengkap, setNamaLengkap] = useState("");
  const [tmpLahir, setTmpLahir] = useState("");
  const [tglLahir, setTglLahir] = useState("");
  const [jenKel, setJenKel] = useState("");
  const [noHp, setNoHp] = useState("");
  const [alamat, setAlamat] = useState("");
  const [jenSim, setJenSim] = useState("");
  const [pdTerakhir, setPdTerakhir] = useState("");
  const [noteSim, setNoteSim] = useState([]);

  const [modalShow, setModalShow] = useState(false);
  const [modalSuccess, setModalSuccess] = useState(false);
  const [titleModal, setTitleModal] = useState("");
  const [iconModal, setIconModal] = useState("");
  const [contentModal, setContentModal] = useState("");

  const [errors, setErrors] = useState([]);

  const { trigger, isMutating } = useSWRMutation(
    `${API_URL_TES}/pemohon/register`,
    sendRequestPost
  );

  const { trigger: triggerFind, isMutating: isMutatingFind } = useSWRMutation(
    `${API_URL_TES}/pemohon/find`,
    sendRequestPost
  );

  const kelamin = [
    {
      name: "Laki-Laki",
      value: "L",
    },
    {
      name: "Perempuan",
      value: "P",
    },
  ];

  const itemSim = [
    {
      name: "SIM A",
      value: "A",
    },
    {
      name: "SIM B",
      value: "B",
    },
    {
      name: "SIM C",
      value: "C",
    },
  ];

  const itemSim2 = [
    {
      name: "SIM A dan C",
      value: "AC",
    },
    {
      name: "SIM B dan C",
      value: "BC",
    },
  ];

  const jenis = [
    {
      name: "Baru",
      value: "Baru",
    },
    {
      name: "Perpanjangan",
      value: "Perpanjangan",
    },
    {
      name: "Peningkatan",
      value: "Peningkatan",
    },
    {
      name: "Penurunan",
      value: "Penurunan",
    },
    {
      name: "Hilang",
      value: "Hilang",
    },
    {
      name: "Rusak",
      value: "Rusak",
    },
  ];

  const itemPd = [
    {
      name: "Tidak Sekolah",
      value: "-",
    },
    {
      name: "SD",
      value: "SD",
    },
    {
      name: "SLTP/SMP/Sederajat",
      value: "SMP",
    },
    {
      name: "SLTA/SMA/Sederajat",
      value: "SMA",
    },
    {
      name: "Diploma 1",
      value: "D1",
    },
    {
      name: "Diploma 2",
      value: "D2",
    },
    {
      name: "Diploma 3",
      value: "D3",
    },
    {
      name: "Diploma 4",
      value: "D4",
    },
    {
      name: "S1",
      value: "S1",
    },
    {
      name: "S2",
      value: "S2",
    },
    {
      name: "S3",
      value: "S3",
    },
  ];

  const pilihSim = (katSimPilih) => {
    if (katSimPilih === "AC" || katSimPilih === "BC") {
      setKatSim(katSimPilih);
      setJenSim('')
      if (katSimPilih === 'AC') {
        setNoteSim([
          {
            katSim: "A",
            jenSim: ""
          },
          {
            katSim: "C",
            jenSim: ""
          },
        ]);
      } else {
        setNoteSim([
          {
            katSim: "B",
            jenSim: ""
          },
          {
            katSim: "C",
            jenSim: ""
          },
        ]);
      }
    } else {
      setKatSim(katSimPilih);
      setNoteSim([]);
    }
  }

  const jenSimPilih = (value, idx) => {
    setNoteSim(prevNoteSim => {
      const updatedNoteSim = [...prevNoteSim];
      updatedNoteSim[idx] = {
        ...updatedNoteSim[idx],
        jenSim: value
      };
      return updatedNoteSim;
    });
  }

  const simpan = async (e) => {
    e.preventDefault();

    const response = await trigger({
      nik: nik,
      nikConfirm: nikConfirm,
      namaLengkap: namaLengkap,
      tmpLahir: tmpLahir,
      tglLahir: tglLahir,
      jenKel: jenKel,
      noHp: noHp,
      alamat: alamat,
      katSim: katSim,
      jenSim: jenSim,
      pdTerakhir: pdTerakhir,
      noteSim: noteSim
    });

    if (response.status === 200) {
      localStorage.setItem("tokenTes", response.data.token);
      localStorage.setItem("tglBerakhir", Date.now());
      localStorage.setItem("tglSelesaiInstruction", Date.now());
      localStorage.setItem("petunjuk", "");

      setModalSuccess(true);
      setTitleModal("Success!");
      setIconModal(
        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-emerald-100 sm:mx-0 sm:h-10 sm:w-10 text-emerald-500">
          <HiCheckCircle size={24} />
        </div>
      );
      setContentModal(response.data.message);
    } else {
      if (response.status === 400) {
        setModalShow(!modalShow);
        setTitleModal("Maaf, terjadi kesalahan!");
        setIconModal(
          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10 text-red-500">
            <HiShieldExclamation size={24} />
          </div>
        );
        setContentModal(response.data.message);
      } else if (response.status === 422) {
        setErrors(response.data.errors);

        setModalShow(true);
        setTitleModal("Maaf, Form Validation!");
        setIconModal(
          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-orange-100 sm:mx-0 sm:h-10 sm:w-10 text-orange-500">
            <HiShieldExclamation size={24} />
          </div>
        );
        setContentModal(response.data.message);
      } else if (response.status === 500) {
        setModalShow(true);
        setTitleModal("Maaf, terjadi kesalahan!");
        setIconModal(
          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10 text-red-500">
            <HiBan size={24} />
          </div>
        );
        setContentModal(response.data.message);
      } else {
        setModalShow(true);
        setTitleModal("Maaf, terjadi kesalahan!");
        setIconModal(
          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10 text-red-500">
            <HiBan size={24} />
          </div>
        );
        setContentModal(response.data.message);
      }
    }
  };

  const findPemohon = async (e) => {
    e.preventDefault();

    const findRes = await triggerFind({ nik: nik });

    if (findRes.status === 200) {
      setNik(findRes.data.nik);
      setNamaLengkap(findRes.data.namaLengkap);
      setTmpLahir(findRes.data.tmpLahir);
      setTglLahir(findRes.data.tglLahir);
      setJenKel(findRes.data.jenKel);
      setNoHp(findRes.data.noHp);
      setPdTerakhir(findRes.data.pdTerakhir);
      setAlamat(findRes.data.alamat);
    } else {
      if (findRes.status === 400) {
        setModalShow(true);
        setTitleModal("Maaf, Ada Terjadi Kesalahan!");
        setIconModal(
          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10 text-red-500">
            <HiShieldExclamation size={24} />
          </div>
        );
        setContentModal(findRes.data.message);
      } else if (findRes.status === 422) {
        if (findRes.data.errors) {
          setErrors(findRes.data.errors);
        }

        setNikConfirm("");
        setNamaLengkap("");
        setTmpLahir("");
        setTglLahir(findRes.data.data.tglLahir);
        setJenKel(findRes.data.data.jenKel);
        setNoHp("");
        setAlamat("");
        setPdTerakhir("");

        setModalShow(true);
        setTitleModal("Maaf, Warning!");
        setIconModal(
          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-orange-100 sm:mx-0 sm:h-10 sm:w-10 text-orange-500">
            <HiShieldExclamation size={24} />
          </div>
        );
        setContentModal(findRes.data.message);
      } else if (findRes.status === 500) {
        setNikConfirm("");
        setNamaLengkap("");
        setTmpLahir("");
        setTglLahir("");
        setJenKel("");
        setNoHp("");
        setAlamat("");
        setPdTerakhir("");

        setModalShow(true);
        setTitleModal("Maaf, NIK Response!");
        setIconModal(
          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10 text-red-500">
            <HiShieldExclamation size={24} />
          </div>
        );
        setContentModal(findRes.data.message);
      } else {
        setModalShow(true);
        setTitleModal("Maaf, Terjadi Kesalahan!");
        setIconModal(
          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10 text-red-500">
            <HiShieldExclamation size={24} />
          </div>
        );
        setContentModal(findRes.data.message);
      }
    }
  };

  const checkLocalStorage = () => {
    const test = "test";
    try {
      localStorage.setItem(test, test);
      localStorage.removeItem(test);
      return true;
    } catch (error) {
      return false;
    }
  };

  useEffect(() => {
    if (!checkLocalStorage()) {
      setModalShow(true);
      setTitleModal("Maaf, Sepertinya Ada Kendala!");
      setIconModal(
        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10 text-red-500">
          <HiShieldExclamation size={24} />
        </div>
      );
      setContentModal(
        "Maaf Anda belum mengaktifkan permission data storage di browser. Silahkan ke Setting > Setelan Situs > Data Situs di perangkat, aktifkan Permission Data Storage browser Anda. Reload/Refresh browser Kemudian ulangi kembali!"
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isMutating && (
        <Loading pesanLoading="Mohon tunggu, sedang menyiapkan soal!" />
      )}

      {isMutatingFind && (
        <Loading pesanLoading="Mohon tunggu, sedang pengecekkan nik!" />
      )}
      <div className="container mx-auto py-10 mt-10 lg:mt-14 overflow-auto">
        <div className="grid grid-cols-12 grid-flow-col gap-3">
          <div className="lg:col-start-4 lg:col-span-6 col-start-1 col-span-12 bg-white p-4 rounded-lg shadow-lg">
            {/* form */}
            <h3 className="font-poppins-semibold md:text-lg lg:mt-4 mt-2 text-indigo-600">
              Pendaftar Tes Online Psikologi SIM
            </h3>
            <p className="text-sm font-poppins-regular text-gray-600">
              Silahkan isi dengan lengkap data Anda dibawah ini kemudian klik
              lanjut untuk mengerjakan soal tes psikologi.
            </p>

            <form onSubmit={simpan}>
              <div className="grid gap-3 mb-6 grid-cols-6 mt-10">
                <div className="col-start-1 md:col-span-3 mb-3 col-span-6">
                  <div className="flex flex-row items-center">
                    <div className="flex-1">
                      <InputNumber
                        label="Nomor Induk Kependudukan"
                        id="nik"
                        value={nik}
                        setValue={setNik}
                        placeholder="Masukkan NIK Anda di sini"
                        errors={errors}
                        eventBlur={findPemohon}
                      />
                    </div>
                    {isMutatingFind && (
                      <div className="ml-2">
                        <label className="block mb-2 text-xs font-poppins-semibold">
                          &nbsp;
                        </label>
                        <div role="status">
                          <svg
                            aria-hidden="true"
                            className="w-4 h-4 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                              fill="currentColor"
                            />
                            <path
                              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                              fill="currentFill"
                            />
                          </svg>
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="md:col-span-3 col-span-6 mb-3">
                  <InputNumber
                    label="Input Ulang NIK"
                    id="nikConfirm"
                    value={nikConfirm}
                    setValue={setNikConfirm}
                    placeholder="Masukkan Konfirmasi NIK Anda di sini"
                    errors={errors}
                  />
                </div>
                <div className="col-start-1 md:col-span-6 col-span-6 mb-3">
                  <InputText
                    label="Nama Lengkap"
                    id="namaLengkap"
                    value={namaLengkap}
                    setValue={setNamaLengkap}
                    placeholder="Masukkan nama lengkap Anda di sini"
                    errors={errors}
                  />
                </div>
                <div className="col-start-1 md:col-span-4 col-span-3 mb-3">
                  <InputText
                    label="Tempat lahir"
                    id="tmpLahir"
                    value={tmpLahir}
                    setValue={setTmpLahir}
                    placeholder="Masukkan tempat lahir Anda di sini"
                    errors={errors}
                  />
                </div>
                <div className="md:col-span-2 col-span-3 mb-3">
                  <InputDate
                    label="Tanggal lahir"
                    id="tglLahir"
                    value={tglLahir}
                    setValue={setTglLahir}
                    placeholder="07/03/1993"
                    errors={errors}
                  />
                </div>
                <div className="col-start-1 md:col-span-3 col-span-6 mb-3">
                  <InputSelect
                    label="Jenis Kelamin"
                    name="jenKel"
                    id="jenKel"
                    value={jenKel}
                    setValue={setJenKel}
                    options={kelamin}
                    errors={errors}
                  />
                </div>
                <div className="md:col-span-3 col-span-6 mb-3">
                  <InputNumber
                    label="Nomor Handphone"
                    id="noHp"
                    value={noHp}
                    setValue={setNoHp}
                    placeholder="08xx"
                    errors={errors}
                  />
                </div>
                <div className="col-start-1 md:col-span-3 col-span-6 mb-3">
                  <InputSelect
                    label="Pendidikan Terakhir"
                    name="pdTerakhir"
                    id="pdTerakhir"
                    value={pdTerakhir}
                    setValue={setPdTerakhir}
                    options={itemPd}
                    errors={errors}
                  />
                </div>
                <div className="col-start-1 md:col-span-6 col-span-6 mb-3">
                  <InputText
                    label="Alamat"
                    id="alamat"
                    value={alamat}
                    setValue={setAlamat}
                    placeholder="Masukkan alamat lengkap Anda"
                    errors={errors}
                  />
                </div>
                <div className="col-start-1 md:col-span-6 col-span-6 mb-3">
                  <label className="block mb-2 text-sm font-poppins-medium">
                    PILIH PERMOHONAN 1 SIM
                  </label>
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-3">
                    {itemSim &&
                      itemSim.map((item, index) => (
                        <CardSim
                          key={`kat-${index}`}
                          name={item.name}
                          value={item.value}
                          setValue={katSim}
                          onClick={() => pilihSim(item.value)}
                        />
                      ))}
                  </div>
                  <div className="col-start-1 md:col-span-6 col-span-6 mt-6 mb-3">
                    <label className="block mb-2 text-sm font-poppins-medium">
                      PILIH PERMOHONAN 2 SIM
                    </label>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-3">
                      {itemSim2 &&
                        itemSim2.map((item, index) => (
                          <CardSim
                            key={`${index}`}
                            name={item.name}
                            value={item.value}
                            setValue={katSim}
                            onClick={() => pilihSim(item.value)}
                          />
                        ))}
                    </div>
                  </div>
                </div>
                <div className="col-span-6">
                  {katSim === "AC" || katSim === "BC" ? (
                    <div className="bg-rose-200 p-3 rounded-xl">
                      {noteSim.map((item, idx) => (
                        <div key={idx} className="col-start-1 md:col-span-3 col-span-6 mb-3">
                          <InputSelect2
                            label={`SIM ${item.katSim}`}
                            name="jenSim"
                            id="jenSim"
                            value={item.jenSim}
                            setValue={jenSimPilih}
                            options={jenis}
                            errors={errors}
                            idx={idx}
                          />
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="col-start-1 md:col-span-3 col-span-6 mb-3">
                      <InputSelect
                        label="Jenis SIM"
                        name="jenSim"
                        id="jenSim"
                        value={jenSim}
                        setValue={setJenSim}
                        options={jenis}
                        errors={errors}
                      />
                    </div>
                  )}
                </div>
                <div className="col-start-1 md:col-span-6 col-span-6 mb-3">
                  <h2 className="font-poppins-semibold text-lg text-center text-black mb-2">
                    Kebijakan PSIKOLOGI SIM Online
                  </h2>
                  <p className="text-xs mb-3 font-poppins-regular text-gray-500 lg:mx-8">
                    Dengan ini saya menyatakan setuju pada kebijakan psikologi
                    sim online dibawah ini:
                  </p>
                  <Policy />

                  <div className="flex flex-row lg:mx-8 mt-3">
                    <div className="flex items-center mb-4">
                      <input
                        id="default-checkbox"
                        type="checkbox"
                        value=""
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                        required
                      />
                      <label
                        htmlFor="default-checkbox"
                        className="ml-3 text-xs font-poppins-regular text-gray-500"
                      >
                        <span className="font-poppins-semibold">
                          Saya menyetujui
                        </span>{" "}
                        semua kebijakan diatas.{" "}
                        <span className="text-blue-500">
                          Silahkan baca kembali kebijakan terlebih dahulu
                          sebelum melanjutkan.
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <button
                type="submit"
                disabled={isMutating}
                className="text-white bg-[#3b5998] hover:bg-[#3b5998]/90 active::bg-[#3b5998]/50  focus:outline-none focus:ring-[#3b5998]/50 font-poppins-medium rounded-lg text-sm px-5 py-3 text-center inline-flex items-center justify-center shadow-md w-full"
              >
                <span>Lanjutkan</span>
                <span className="ml-2">
                  <HiArrowRight />
                </span>
              </button>
            </form>
          </div>
        </div>
      </div>

      {modalShow && (
        <ModalAll
          title={titleModal}
          icon={iconModal}
          content={contentModal}
          btnRefClose={() => setModalShow(false)}
        />
      )}

      {modalSuccess && (
        <ModalCountdown
          title={titleModal}
          icon={iconModal}
          content={contentModal}
          url={() => navigate("/responden")}
        />
      )}
    </>
  );
};

export default Daftar;
