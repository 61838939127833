import React from "react";

const InputDateForm = ({
  label,
  id,
  value,
  setValue,
  placeholder,
  min,
  errors,
}) => {
  const validate = errors.find((element) => element.path === id);

  return (
    <>
      <label className="block mb-2 text-xs font-poppins-semibold">
        {label}
      </label>
      <input
        type="date"
        id={id}
        className={
          validate
            ? "bg-[#fee2e2] text-sm rounded-md block w-full p-2.5 focus:ring-red-500 focus:border-red-500 border-[#f87171] placeholder:text-[#f87171] font-poppins-regular focus:bg-[#f9fafb]"
            : "bg-[#E5E7EB] text-sm rounded-md block w-full p-2.5 focus:ring-blue-500 focus:border-blue-500 border-[#E5E7EB] placeholder:text-[#9CA3AF] font-poppins-regular focus:bg-[#f9fafb]"
        }
        value={value}
        onChange={(e) => setValue(e.target.value)}
        placeholder={placeholder}
        autoComplete="false"
        inputMode="numeric"
        min={min}
        required
      />

      {validate && (
        <p className="text-red-500 font-poppins-regular text-xs mt-1">
          * {validate.msg}
        </p>
      )}
    </>
  );
};

export default InputDateForm;
