import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";

import {
  HiArrowRight,
  HiCheckCircle,
  HiOutlineCalendar,
  HiOutlineDocumentAdd,
  HiShieldExclamation,
} from "react-icons/hi";

import useSWR from "swr";
import useSWRMutation from "swr/mutation";
import { sendReqGet, sendRequestPost } from "../../swr/axiosFetch";

import InputSelect from "../../components/Form/InputSelect";
import InputNumber from "../../components/Form/InputNumber";
import CardSim from "../../components/CardSim";
import { API_URL_TES } from "../../config";
import Loading from "../../components/Loading";
import InputDateForm from "../../components/Form/InputDateForm";
import ModalAll from "../../components/Modal/ModalAll";
import ModalCountdown from "../../components/Modal/ModalCountdown";

const Konseling = () => {
  const [idKons, setIdKons] = useState("");
  const [katSim, setKatSim] = useState("");
  const [nik, setNik] = useState("");
  const [noHp, setNoHp] = useState("");
  const [jenSim, setJenSim] = useState("");
  const [jadwalId, setJadwalId] = useState("");
  const [tglKonseling, setTglKonseling] = useState("");

  const tglNow = Date.now();
  const tglMin = moment(tglNow).add(1, "days");
  const tglMinFormat = moment(tglMin).format("YYYY-MM-DD");

  const [nextInput, setNextInput] = useState(0);

  const [errors, setErrors] = useState([]);

  const navigate = useNavigate();

  const [modalShow, setModalShow] = useState(false);
  const [modalSuccess, setModalSuccess] = useState(false);
  const [modalPermission, setModalPermission] = useState(false);
  const [titleModal, setTitleModal] = useState("");
  const [iconModal, setIconModal] = useState("");
  const [contentModal, setContentModal] = useState("");

  const { data, isLoading } = useSWR(
    `${API_URL_TES}/pemohon/jadwal-konseling`,
    sendReqGet
  );

  const { trigger, isMutating } = useSWRMutation(
    `${API_URL_TES}/pemohon/register-konseling`,
    sendRequestPost
  );

  const { trigger: validateTrigger, isMutating: isMutatingValidate } =
    useSWRMutation(
      `${API_URL_TES}/pemohon/validate-konseling`,
      sendRequestPost
    );

  const itemSim = [
    {
      name: "SIM A",
      value: "A",
    },
    {
      name: "SIM B",
      value: "B",
    },
    {
      name: "SIM C",
      value: "C",
    },
  ];

  const jenis = [
    {
      name: "Baru",
      value: "Baru",
    },
    {
      name: "Perpanjangan",
      value: "Perpanjangan",
    },
    {
      name: "Peningkatan",
      value: "Peningkatan",
    },
    {
      name: "Penurunan",
      value: "Penurunan",
    },
    {
      name: "Hilang",
      value: "Hilang",
    },
    {
      name: "Rusak",
      value: "Rusak",
    },
  ];

  const simpan = async (e) => {
    e.preventDefault();

    const response = await trigger({
      nik: nik,
      noHp: noHp,
      katSim: katSim,
      jenSim: jenSim,
      jadwalId: jadwalId,
      tglKonseling: tglKonseling,
    });

    if (response.status === 200) {
      setIdKons(response.data._id);

      setModalSuccess(true);
      setTitleModal("Success!");
      setIconModal(
        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-emerald-100 sm:mx-0 sm:h-10 sm:w-10 text-emerald-500">
          <HiCheckCircle size={24} />
        </div>
      );
      setContentModal(response.data.message);
    } else {
      if (response.status === 400) {
        setModalShow(true);
        setTitleModal("Opps, Maaf!");
        setIconModal(
          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-orange-100 sm:mx-0 sm:h-10 sm:w-10 text-orange-500">
            <HiShieldExclamation size={24} />
          </div>
        );
        setContentModal(response.data.message);
      } else if (response.status === 422) {
        setErrors(response.data.errors);
        setModalShow(true);
        setTitleModal("Opps, Maaf, Form Validation!");
        setIconModal(
          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10 text-red-500">
            <HiShieldExclamation size={24} />
          </div>
        );
        setContentModal(response.data.message);
      } else if (response.status === 500) {
        setModalShow(true);
        setTitleModal("Opps, Maaf!");
        setIconModal(
          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10 text-red-500">
            <HiShieldExclamation size={24} />
          </div>
        );
        setContentModal(response.data.message);
      } else {
        setModalShow(true);
        setTitleModal("Opps, Maaf!");
        setIconModal(
          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-orange-100 sm:mx-0 sm:h-10 sm:w-10 text-orange-500">
            <HiShieldExclamation size={24} />
          </div>
        );
        setContentModal(
          "Terjadi kesalahan, silahkan ulangi beberapa saat kembali!"
        );
      }
    }
  };

  const validateKonseling = async (e) => {
    e.preventDefault();

    const resValidate = await validateTrigger({
      nik: nik,
      noHp: noHp,
      katSim: katSim,
      jenSim: jenSim,
    });

    if (resValidate.status === 200) {
      setNextInput(1);
    } else if (resValidate.status === 422) {
      setErrors(resValidate.data.errors);
      setModalShow(true);
      setTitleModal("Opps, Maaf!");
      setIconModal(
        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-orange-100 sm:mx-0 sm:h-10 sm:w-10 text-orange-500">
          <HiShieldExclamation size={24} />
        </div>
      );
      setContentModal(resValidate.data.message);
    } else {
      setModalShow(true);
      setTitleModal("Opps, Maaf!");
      setIconModal(
        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-orange-100 sm:mx-0 sm:h-10 sm:w-10 text-orange-500">
          <HiShieldExclamation size={24} />
        </div>
      );
      setContentModal(resValidate.data.message);
    }
  };

  const checkLocalStorage = () => {
    const test = "test";
    try {
      localStorage.setItem(test, test);
      localStorage.removeItem(test);
      return true;
    } catch (error) {
      return false;
    }
  };

  useEffect(() => {
    if (!checkLocalStorage()) {
      setModalPermission(true);
      setTitleModal("Opps, Maaf Ada Kendala!");
      setIconModal(
        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-orange-100 sm:mx-0 sm:h-10 sm:w-10 text-orange-500">
          <HiShieldExclamation size={24} />
        </div>
      );
      setContentModal(
        "Maaf Anda belum mengaktifkan permission data storage di browser. Silahkan ke Setting > Setelan Situs > Data Situs di perangkat, aktifkan Permission Data Storage browser Anda. Reload/Refresh browser Kemudian ulangi kembali!"
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isMutating && <Loading pesanLoading="Mohon tunggu...!" />}

      {isMutatingValidate && <Loading pesanLoading="Mohon tunggu...!" />}

      {isLoading && (
        <Loading pesanLoading="Mohon tunggu, sedang mengambil data!" />
      )}

      <div className="container mx-auto py-10 mt-10 lg:mt-14 overflow-auto">
        <div className="grid grid-cols-12 grid-flow-col gap-3">
          <div className="lg:col-start-4 lg:col-span-6 col-start-1 col-span-12 bg-white p-4 rounded-lg shadow-lg">
            <div className="shadow rounded-full pt-4 pb-4 pl-10 pr-10">
              <div className="relative flex items-center justify-between w-full">
                <div className="absolute left-0 top-2/4 h-0.5 w-full -translate-y-2/4 bg-gray-300"></div>
                {nextInput === 1 && (
                  <div className="absolute left-0 top-2/4 h-0.5 w-full -translate-y-2/4 bg-orange-500 transition-all duration-500"></div>
                )}

                <div className="relative z-10 grid w-10 h-10 font-bold text-white transition-all duration-300 bg-orange-500 rounded-full place-items-center">
                  <HiOutlineDocumentAdd />
                </div>
                <div
                  className={
                    nextInput === 1
                      ? `relative z-10 grid w-10 h-10 font-bold text-white transition-all duration-300 bg-orange-500 rounded-full place-items-center`
                      : `relative z-10 grid w-10 h-10 font-bold text-gray-900 transition-all duration-300 bg-gray-300 rounded-full place-items-center`
                  }
                >
                  <HiOutlineCalendar />
                </div>
              </div>
            </div>

            {nextInput === 0 && (
              <>
                <h3 className="font-poppins-semibold md:text-lg lg:mt-4 mt-2 text-indigo-600">
                  Pendaftar Konseling Tes Psikologi SIM
                </h3>
                <p className="text-sm font-poppins-regular text-gray-600">
                  Silahkan isi dengan lengkap data dibawah ini untuk melakukan
                  konseling, kemudian klik lanjut untuk menentukan jadwal
                  konseling.
                </p>

                <form onSubmit={validateKonseling}>
                  <div className="grid gap-3 mb-6 grid-cols-6 mt-10">
                    <div className="col-start-1 md:col-span-3 mb-3 col-span-6">
                      <div className="flex flex-row items-center">
                        <div className="flex-1">
                          <InputNumber
                            label="Nomor Induk Kependudukan"
                            id="nik"
                            value={nik}
                            setValue={setNik}
                            placeholder="Masukkan NIK Anda di sini"
                            errors={errors}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="md:col-span-3 col-span-6 mb-3">
                      <InputNumber
                        label="Nomor Whatsapp"
                        id="noHp"
                        value={noHp}
                        setValue={setNoHp}
                        placeholder="08xx"
                        errors={errors}
                      />
                      <label
                        id="noHp"
                        className="text-xs text-orange-500 font-poppins-regular"
                      >
                        * Pastikan nomor hp Anda aktif, agar bisa dihubungi oleh
                        petugas kami.
                      </label>
                    </div>
                    <div className="col-start-1 md:col-span-6 col-span-6 mb-3">
                      <label className="block mb-2 text-sm font-poppins-medium">
                        PILIH PERMOHONAN SIM
                      </label>
                      <div className="grid grid-cols-1 md:grid-cols-3 gap-3">
                        {itemSim &&
                          itemSim.map((item, index) => (
                            <CardSim
                              key={`kat-${index}`}
                              name={item.name}
                              value={item.value}
                              setValue={katSim}
                              onClick={() => setKatSim(item.value)}
                            />
                          ))}
                      </div>
                    </div>
                    <div className="col-start-1 md:col-span-3 col-span-6 mb-3">
                      <InputSelect
                        label="Jenis SIM"
                        name="jenSim"
                        id="jenSim"
                        value={jenSim}
                        setValue={setJenSim}
                        options={jenis}
                        errors={errors}
                      />
                    </div>
                  </div>
                  <button
                    type="submit"
                    disabled={isMutating}
                    className="text-white bg-[#3b5998] hover:bg-[#3b5998]/90 active::bg-[#3b5998]/50  focus:outline-none focus:ring-[#3b5998]/50 font-poppins-medium rounded-lg text-sm px-5 py-3 text-center inline-flex items-center justify-center shadow-md w-full"
                  >
                    <span>Lanjutkan</span>
                    <span className="ml-2">
                      <HiArrowRight />
                    </span>
                  </button>
                </form>
              </>
            )}

            {nextInput === 1 && (
              <>
                <h3 className="font-poppins-semibold md:text-lg lg:mt-4 mt-2 text-indigo-600">
                  Jadwal Konseling
                </h3>
                <p className="text-sm font-poppins-regular text-gray-600">
                  Silahkan pilih jadwal konseling dibawah ini.
                </p>

                <form onSubmit={simpan}>
                  <div className="grid gap-3 mb-6 grid-cols-6 mt-10">
                    <div className="md:col-span-2 col-span-3 mb-3">
                      <InputDateForm
                        label="Tanggal Konseling"
                        id="tglKonseling"
                        value={tglKonseling}
                        setValue={setTglKonseling}
                        placeholder="07/03/1993"
                        min={tglMinFormat}
                        errors={errors}
                      />
                    </div>
                    <div className="col-start-1 md:col-span-6 col-span-6 mb-3">
                      <label className="block mb-2 text-sm font-poppins-medium">
                        PILIH JAM KONSELING
                      </label>
                      <div className="grid grid-cols-1 md:grid-cols-3 gap-3">
                        {data &&
                          data.map((item, index) => (
                            <CardSim
                              key={`jadwal-${index}`}
                              name={item.jamJadwal}
                              value={item._id}
                              setValue={jadwalId}
                              onClick={() => setJadwalId(item._id)}
                            />
                          ))}
                      </div>
                    </div>
                  </div>
                  <button
                    type="submit"
                    disabled={isMutating}
                    className="text-white bg-[#3b5998] hover:bg-[#3b5998]/90 active::bg-[#3b5998]/50  focus:outline-none focus:ring-[#3b5998]/50 font-poppins-medium rounded-lg text-sm px-5 py-3 text-center inline-flex items-center justify-center shadow-md w-full"
                  >
                    <span>Buat Janji Konseling</span>
                    <span className="ml-2">
                      <HiArrowRight />
                    </span>
                  </button>
                </form>
              </>
            )}
          </div>
        </div>
      </div>

      {modalShow && (
        <ModalAll
          title={titleModal}
          icon={iconModal}
          content={contentModal}
          btnRefClose={() => setModalShow(false)}
        />
      )}

      {modalSuccess && (
        <ModalCountdown
          title={titleModal}
          icon={iconModal}
          content={contentModal}
          url={() => navigate(`/konseling/${idKons}`)}
        />
      )}

      {modalPermission && (
        <ModalAll
          title={titleModal}
          icon={iconModal}
          content={contentModal}
          btnNext={() => navigate("/")}
        />
      )}
    </>
  );
};

export default Konseling;
