import React from "react";
import SocialGraduate from "../../assets/images/social-graduate.png";
import { HiCheck } from "react-icons/hi";

const ErrorPage = React.memo(() => {
  return (
    <div className="container mx-auto mt-[5.8rem] lg:mt-24">
      <div className="grid grid-cols-12 grid-flow-col gap-3">
        <div className="lg:col-start-4 lg:col-span-6 col-start-1 col-span-12 bg-white p-4 rounded-lg shadow-lg mx-4 lg:mx-0">
          <h2 className="text-center font-poppins-semibold text-2xl text-red-600">
            Error
          </h2>
          <p className="font-poppins-regular text-center text-sm mb-4 text-black">
            Maaf terjadi kesalahan
          </p>
          <img
            className="h-auto max-w-lg mx-auto"
            src={SocialGraduate}
            width="180px"
            alt="description"
          />

          <p className="text-center font-poppins-regular text-sm mt-4 lg:mx-20 mx-4">
            Error koneksi, silahkan klik tombol reload dibawah ini!
          </p>

          <button
            type="button"
            onClick={() => {
              window.location.reload(false);
            }}
            className="text-white bg-[#059669] hover:bg-[#059669]/90 active::bg-[#059669]/50  focus:outline-none focus:ring-[#059669]/50 font-poppins-medium rounded-lg text-sm px-5 py-3 text-center inline-flex items-center justify-center shadow-md w-full my-8"
          >
            <span className="mr-2">
              <HiCheck />
            </span>
            <span>Reload Halaman</span>
          </button>
        </div>
      </div>
    </div>
  );
});

export default ErrorPage;
