import React from "react";
import { Link } from "react-router-dom";

const CardMenu = ({ namaMenu, icon, descMenu, to }) => {
  return (
    <Link
      to={to}
      className="col-span-6 lg:col-span-3 bg-white rounded-lg shadow p-4 hover:shadow-lg"
    >
      <div className="flex lg:flex-row flex-col items-center">
        <div className="bg-gradient-to-r from-orange-500 to-orange-200 text-white rounded-full p-2 shadow">
          {icon}
        </div>
        <div className="lg:ml-2 max-sm:mt-2">
          <h2 className="font-poppins-semibold text-emerald-600 text-sm text-center lg:text-left lg:text-lg">
            {namaMenu}
          </h2>
          <p className="font-poppins-regular text-xs text-gray-400 text-center lg:text-left">
            {descMenu}
          </p>
        </div>
      </div>
    </Link>
  );
};

export default CardMenu;
