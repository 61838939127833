import React, { useState } from "react";
import { Link } from "react-router-dom";

import InputNumber from "../../components/Form/InputNumber";
import InputDate from "../../components/Form/InputDate";
import { HiOutlineArrowCircleRight, HiOutlineSearch } from "react-icons/hi";

import useSWRMutation from "swr/mutation";
import { sendRequestPost } from "../../swr";
import Loading from "../Loading";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import NotFoundData from "../ErrorPage/NotFoundData";

const CariOffline = () => {
  const [nik, setNik] = useState("");
  const [tglLahir, setTglLahir] = useState("");

  const [errors, setErrors] = useState([]);
  const MySwal = withReactContent(Swal);

  const { trigger, data, error, isMutating } = useSWRMutation(
    `https://srvpay1.psisim.net/pemohon/carihasil-tes`,
    sendRequestPost
  );

  const cariHasil = async (e) => {
    e.preventDefault();

    try {
      await trigger({
        nik: nik,
        tglLahir: tglLahir,
      });
    } catch (error) {
      console.log(error);
      if (error.status === 400) {
        MySwal.fire("Error!", error.info.message, "error");
      } else if (error.status === 422) {
        setErrors(error.info.errors);
        MySwal.fire("Error!", error.info.message, "error");
      } else if (error.status === 204) {
        setErrors(error.info.errors);
        MySwal.fire("204!", "Not Found!", "warning");
      } else {
        MySwal.fire("No Result!", "Data tidak ditemukan!", "warning");
      }
    }
  };

  if (isMutating)
    return (
      <Loading pesanLoading="Mohon Tunggu, sedang melakukan pencarian..." />
    );

  if (error) return <NotFoundData />;

  return (
    <>
      <div className="grid grid-cols-12 grid-flow-col gap-3">
        <div className="lg:col-start-4 lg:col-span-6 col-start-1 col-span-12 bg-white p-4 rounded-b-lg shadow-lg">
          <h3 className="font-poppins-semibold md:text-lg lg:mt-4 mt-2 text-indigo-600">
            Cari Hasil Tes Offline
          </h3>
          <p className="text-sm font-poppins-regular text-gray-600">
            Silahkan input nik dan tanggal lahir untuk mencari hasil tes
            psikologi sim Anda.
          </p>

          <div className="bg-yellow-100 px-4 py-2 rounded-lg font-poppins-regular text-sm text-yellow-700 mt-3">
            <strong>Info!</strong>
            <p>
              Hasil tes yang dilaksanakan secara offline akan muncul{" "}
              <strong>H+1</strong> setelah tes di laksanakan dan{" "}
              <strong>sudah melakukan pembayaran</strong>!
            </p>
          </div>

          <form onSubmit={cariHasil}>
            <div className="grid gap-3 mb-6 grid-cols-6 mt-10">
              <div className="col-start-1 col-span-6 lg:col-span-4">
                <InputNumber
                  label="Nomor Induk Kependudukan"
                  id="nik"
                  value={nik}
                  setValue={setNik}
                  placeholder="Masukkan NIK Anda di sini"
                  errors={errors}
                />
              </div>
              <div className="col-span-6 lg:col-span-2">
                <InputDate
                  label="Tanggal lahir"
                  id="tglLahir"
                  value={tglLahir}
                  setValue={setTglLahir}
                  placeholder="07/03/1993"
                  errors={errors}
                />
              </div>
            </div>
            <button
              type="submit"
              disabled={isMutating}
              className="text-white bg-[#3b5998] hover:bg-[#3b5998]/90 active::bg-[#3b5998]/50  focus:outline-none focus:ring-[#3b5998]/50 font-poppins-medium rounded-lg text-sm px-5 py-3 text-center inline-flex items-center justify-center shadow-md w-full"
            >
              <span className="mr-2">
                <HiOutlineSearch size={20} />
              </span>
              <span>Cari Hasil Tes</span>
            </button>
          </form>
        </div>
      </div>

      <div className="grid grid-cols-12 grid-flow-col gap-3 mt-4">
        <div className="lg:col-start-4 lg:col-span-6 col-start-1 col-span-12">
          {data ? (
            <div className="grid grid-cols-1 gap-y-3">
              {data.data.map((hasil, index) => {
                return (
                  <Link
                    to={`/detailhasiloffline/${hasil.id}`}
                    key={index}
                    className="flex flex-row items-center bg-white rounded-lg shadow hover:shadow-lg hover:bg-gray-100"
                  >
                    <div className="flex-1 p-4">
                      <p className="text-gray-600 font-poppins-regular text-sm">
                        {hasil.nik}
                      </p>
                      <h2 className="text-sm lg:text-lg text-emerald-600 font-poppins-semibold">
                        {hasil.namaLengkap}
                      </h2>
                      <p className="text-black font-poppins-regular text-xs lg:text-sm">
                        {hasil.tmpLahir}, {hasil.tglLahir}
                      </p>

                      <div className="flex flex-row item-center gap-3">
                        <div>
                          <p className="text-gray-400 font-poppins-reguler text-xs mt-4">
                            Tanggal Tes
                          </p>
                          <p className="text-black font-poppins-regular text-xs lg:text-sm mt-2">
                            {hasil.createdAt}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="border-l-2 relative h-full border-dashed mx-5 pr-5">
                      <div className="absolute rounded-full w-5 h-5 bg-blue-600 -mt-2 -left-3 -top-1"></div>
                      <div className="absolute rounded-full w-5 h-5 bg-blue-600 -mb-2 -left-3 -bottom-1"></div>
                    </div>
                    <div className="flex-1 p-4">
                      <div className="text-4xl font-poppins-semibold">
                        {hasil.kategoriSim}
                      </div>
                      <p className="text-gray-900 text-sm font-poppins-regular">
                        {hasil.jenSim}
                      </p>
                      {hasil.hasilTes === "MS" ? (
                        <p className="text-emerald-600 text-lg font-poppins-regular mt-4">
                          MEMENUHI SYARAT
                        </p>
                      ) : (
                        <p className="text-red-600 text-lg font-poppins-regular mt-4">
                          BELUM MEMENUHI SYARAT
                        </p>
                      )}
                    </div>
                    <div className="mx-2 text-gray-400 hover:text-orange-500">
                      <HiOutlineArrowCircleRight size={28} />
                    </div>
                  </Link>
                );
              })}
            </div>
          ) : (
            <p className="text-gray-300 font-poppins-regular text-xs text-center">
              Data hasil tes...
            </p>
          )}
        </div>
      </div>
    </>
  );
};

export default CariOffline;
