import React, { useRef } from "react";
import Countdown, { zeroPad } from "react-countdown";
import Loading from "../../components/Loading";
import moment from "moment-timezone";

const TimerSoal2 = React.memo(
  ({
    _id,
    buttonNextRef,
    isMutating,
    isMutatingInstruction,
    isLoading,
    error,
  }) => {
    const basePathTimerRemaining = useRef(null);

    const FULL_DASH_ARRAY = 283;
    const WARNING_THRESHOLD = 30;
    const ALERT_THRESHOLD = 10;

    const COLOR_CODES = {
      info: {
        color: "green",
      },
      warning: {
        color: "orange",
        threshold: WARNING_THRESHOLD,
      },
      alert: {
        color: "red",
        threshold: ALERT_THRESHOLD,
      },
    };

    // const tglNow = Date.now();
    // const result = new Date(moment.tz(tglNow, "Asia/Jakarta")).getTime();
    const getTglStorage = localStorage.getItem("tglBerakhir");
    // const durasi = Math.floor((getTglStorage - result) / 1000);
    // const durasi2 = durasi * 1000;
    // const durasi3 = Date.now() + durasi2;
    // console.log(`soal:${durasi}`);

    const tglFirst = moment();
    const tglSecond = moment(new Date(getTglStorage));

    const durasi = tglSecond.diff(tglFirst, "seconds");
    const durasi2 = parseInt(durasi) * 1000;
    const durasi3 = Date.now() + parseInt(durasi2);

    // console.log(`soal:${durasi}`);

    let TIME_LIMIT = isNaN(durasi) ? 10 : parseInt(durasi);

    let remainingPathColor = COLOR_CODES.info.color;

    const waktuRender = ({ total, minutes, seconds, completed }) => {
      if (completed) {
        // Render a completed state
        return <Loading />;
      } else {
        const totalWaktu = total / 1000;
        setCircleDasharray(totalWaktu);
        setRemainingPathColor(totalWaktu);

        return (
          <div className="base-timer">
            <svg
              className="base-timer__svg"
              viewBox="0 0 100 100"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g className="base-timer__circle">
                <circle
                  className="base-timer__path-elapsed"
                  cx="50"
                  cy="50"
                  r="40"
                ></circle>
                <path
                  id="base-timer-path-remaining"
                  ref={basePathTimerRemaining}
                  strokeDasharray="283"
                  className={`base-timer__path-remaining ${remainingPathColor}`}
                  d="M 50, 50 
              m -40, 0 
              a 40,40 0 1,0 80,0 
              a 40,40 0 1,0 -80,0"
                ></path>
              </g>
            </svg>
            <span id="base-timer-label" className="base-timer__label">
              {zeroPad(minutes)}:{zeroPad(seconds)}
            </span>
          </div>
        );
      }
    };

    const setRemainingPathColor = (timeRight) => {
      const { alert, warning, info } = COLOR_CODES;
      if (parseInt(timeRight) <= alert.threshold) {
        basePathTimerRemaining.current?.classList.remove(warning.color);
        basePathTimerRemaining.current?.classList.add(alert.color);
      } else if (parseInt(timeRight) <= warning.threshold) {
        basePathTimerRemaining.current?.classList.remove(info.color);
        basePathTimerRemaining.current?.classList.add(warning.color);
      } else {
        basePathTimerRemaining.current?.classList.add(info.color);
        basePathTimerRemaining.current?.classList.remove(alert.color);
        basePathTimerRemaining.current?.classList.remove(warning.color);
      }
    };

    const calculateTimeFraction = (timeRight) => {
      const rawTimeFraction = parseInt(timeRight) / TIME_LIMIT;
      return rawTimeFraction - (1 / TIME_LIMIT) * (1 - rawTimeFraction);
    };

    const setCircleDasharray = (timeRight) => {
      const circleDasharray = `${(
        calculateTimeFraction(timeRight) * FULL_DASH_ARRAY
      ).toFixed(0)} 283`;

      basePathTimerRemaining.current?.setAttribute(
        "stroke-dasharray",
        circleDasharray
      );
    };

    if (durasi <= -10) {
      window.location.reload(false);
    } else if (durasi <= 0 && durasi > -9) {
      return <Loading />;
    }

    if (
      isMutating ||
      isLoading ||
      error ||
      isMutatingInstruction ||
      isNaN(durasi3)
    )
      return <Loading />;

    return (
      <Countdown
        key={_id}
        date={durasi3}
        intervalDelay={0}
        overtime={false}
        precision={3}
        renderer={waktuRender}
        onComplete={() => buttonNextRef.current?.click()}
      />
    );
  }
);

export default TimerSoal2;
