import React, { useState } from "react";
import { HiOutlineMenu } from "react-icons/hi";
import { NavLink } from "react-router-dom";

import LogoPsi from "../../../assets/svg/logo-psisimku.svg";

const Header = () => {
  const [showMenu, setShowMenu] = useState(false);

  return (
    <header className="fixed top-0 bg-gray-100 font-sans w-full m-0 z-50">
      <nav className="bg-white shadow">
        <div className="container mx-auto px-4">
          <div className="flex items-center justify-between py-4">
            <div>
              <img
                src={LogoPsi}
                alt="PSISIMKU"
                width={40}
                className="text-blue-600"
              />
            </div>

            <div className="hidden sm:flex sm:items-center">
              <NavLink
                to="/"
                className="text-gray-800 text-sm font-poppins-semibold hover:text-blue-600 mr-4"
              >
                Home
              </NavLink>
              <NavLink
                to="https://psisimku.id"
                className="text-gray-800 text-sm font-poppins-semibold hover:text-blue-600 mr-4"
              >
                Tentang Kami
              </NavLink>
              <NavLink
                to="https://psisimku.id"
                className="text-gray-800 text-sm font-poppins-semibold hover:text-blue-600 mr-4"
              >
                Kebijakan
              </NavLink>
              <NavLink
                to="https://wa.me/+628159933800?text=Hallo%20admin%20psi%20sim%20online..."
                className="text-gray-800 text-sm font-poppins-semibold hover:text-blue-600 mr-4"
              >
                Hubungi Kami
              </NavLink>
            </div>

            <div className="hidden sm:flex sm:items-center">
              <NavLink
                href="/konseling"
                className="text-gray-800 text-sm font-poppins-semibold border px-4 py-2 rounded-lg hover:text-blue-600 hover:border-blue-600"
              >
                Bantuan?
              </NavLink>
            </div>

            <button
              type="button"
              onClick={() => setShowMenu(!showMenu)}
              className="sm:hidden cursor-pointer z-50 text-rose-600"
            >
              <HiOutlineMenu size={24} />
            </button>
          </div>

          <div
            className={
              showMenu
                ? "block sm:hidden bg-white border-t-2 py-2"
                : "hidden bg-white border-t-2 py-2"
            }
          >
            <div className="flex flex-col">
              <NavLink
                to="/"
                className="text-gray-800 text-sm font-poppins-semibold hover:text-blue-600 mb-1"
              >
                Home
              </NavLink>
              <NavLink
                to="/"
                className="text-gray-800 text-sm font-poppins-semibold hover:text-blue-600 mb-1"
              >
                Tentang Kami
              </NavLink>
              <NavLink
                to="/"
                className="text-gray-800 text-sm font-poppins-semibold hover:text-blue-600 mb-1"
              >
                Kebijakan
              </NavLink>
              <NavLink
                to="/"
                className="text-gray-800 text-sm font-poppins-semibold hover:text-blue-600 mb-1"
              >
                Hubungi Kami
              </NavLink>
              <div className="flex justify-between items-center border-t-2 pt-2">
                <NavLink
                  href="/"
                  className="text-gray-800 text-sm font-poppins-semibold border px-4 py-1 rounded-lg hover:text-blue-600 hover:border-blue-600 w-full"
                >
                  Bantuan?
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
