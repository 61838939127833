import React, { useEffect, useRef, useState, useCallback } from "react";
import jwtDecode from "jwt-decode";
import { useNavigate } from "react-router-dom";

import SoalGambar from "../../components/MasterSoal/SoalGambar";
import SoalText from "../../components/MasterSoal/SoalText";

import useSWR from "swr";
import useSWRMutation from "swr/mutation";
import { sendRequestGet, sendRequestPut } from "../../swr/axiosFetch";

import { API_URL_TES } from "../../config";
import {
  HiArrowRight,
  HiCheckCircle,
  HiOutlineClock,
  HiOutlineQuestionMarkCircle,
  HiShieldExclamation,
} from "react-icons/hi";
import SoalPola from "../../components/MasterSoal/SoalPola";
import TimerSoal2 from "./TimerSoal2";
import Finish from "../Finish";
import moment from "moment";
import Instruction from "./Instruction";
import Modal from "../../components/Modal";
import ErrorPage from "../ErrorPage";
import Loading from "../../components/Loading";
import ModalAll from "../../components/Modal/ModalAll";
import ModalCountdown from "../../components/Modal/ModalCountdown";

const ResIndex = () => {
  const navigate = useNavigate();

  // const MySwal = withReactContent(Swal);

  const [showPetunjuk, setShowPetunjuk] = useState(false);

  const [modalShow, setModalShow] = useState(false);
  const [modalSuccess, setModalSuccess] = useState(false);
  const [titleModal, setTitleModal] = useState("");
  const [iconModal, setIconModal] = useState("");
  const [contentModal, setContentModal] = useState("");

  const buttonNextRef = useRef(null);
  const btnPetunjuk = useRef(null);

  const { data, error, isLoading, mutate } = useSWR(
    `${API_URL_TES}/pemohon/getsoal`,
    sendRequestGet,
    {
      loadingTimeout: 3000,
      onSuccess(data) {
        const tglFinish = moment();
        const addFinish = moment(tglFinish).add(10, "seconds");
        localStorage.setItem("finishBerakhir", addFinish);
        if (data) {
          localStorage.setItem("tglBerakhir", data.tglBerakhir);

          if (data.readInstruction) {
            localStorage.setItem(
              "tglSelesaiInstruction",
              data.tglSelesaiInstruction
            );

            // const datFirst = moment();
            // const datSec = moment(data.tglSelesaiInstruction);
            // const diffTot = datSec.diff(datFirst, "seconds");

            // console.log(`diff:${diffTot}`);

            localStorage.setItem("petunjuk", data.instruction);
          }
        }
      },
    }
  );

  const { trigger, isMutating } = useSWRMutation(
    `${API_URL_TES}/pemohon/nextsoal`,
    sendRequestPut,
    {
      onSuccess() {
        mutate();
      },
    }
  );

  const { trigger: triggerInstruction, isMutating: isMutatingInstruction } =
    useSWRMutation(`${API_URL_TES}/pemohon/skip-instruction`, sendRequestPut, {
      loadingTimeout: 3000,
      onSuccess() {
        mutate();
      },
    });

  useEffect(() => {
    // get refresh token
    checkToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkToken = useCallback(() => {
    const token = localStorage.getItem("tokenTes");
    if (token) {
      const decoded = jwtDecode(token);

      const currentDate = new Date();
      if (decoded.exp * 1000 < currentDate.getTime()) {
        navigate("/");
      }
    } else {
      navigate("/");
    }
  }, [navigate]);

  const skipInstruction = useCallback(
    async (e) => {
      e.preventDefault();
      const skipInstructionRes = await triggerInstruction({
        masterId: data._id,
      });

      if (skipInstructionRes) {
        if (skipInstructionRes.status === 200) {
          window.scrollTo(0, 0);
          setModalSuccess(true);
          setTitleModal("Melanjutkan Soal!");
          setIconModal(
            <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-emerald-100 sm:mx-0 sm:h-10 sm:w-10 text-emerald-500">
              <HiCheckCircle size={24} />
            </div>
          );
          setContentModal(skipInstructionRes.data.message);
        } else if (skipInstructionRes.status > 400) {
          setModalShow(true);
          setTitleModal("Maaf, Ada Terjadi Kesalahan!");
          setIconModal(
            <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10 text-red-500">
              <HiShieldExclamation size={24} />
            </div>
          );
          setContentModal(skipInstructionRes.data.message);
        } else {
          setModalShow(true);
          setTitleModal("Opps, Maaf!");
          setIconModal(
            <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10 text-red-500">
              <HiShieldExclamation size={24} />
            </div>
          );
          setContentModal(
            "Terjadi kesalahan, mohon periksa internet Anda, silahkan reload browser Anda!"
          );
        }
      } else {
        setModalShow(true);
        setTitleModal("Opps, Maaf!");
        setIconModal(
          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10 text-red-500">
            <HiShieldExclamation size={24} />
          </div>
        );
        setContentModal(
          "Terjadi kesalahan, mohon periksa internet Anda, silahkan reload browser Anda!"
        );
      }
    },
    [data, triggerInstruction]
  );

  const nextSoal = useCallback(
    async (e) => {
      e.preventDefault();

      const nextsoalRes = await trigger({
        masterId: data._id,
      });

      if (nextsoalRes) {
        if (nextsoalRes.status === 200) {
          window.scrollTo(0, 0);
          setModalSuccess(true);
          setTitleModal("Soal Berikutnya!");
          setIconModal(
            <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-emerald-100 sm:mx-0 sm:h-10 sm:w-10 text-emerald-500">
              <HiCheckCircle size={24} />
            </div>
          );
          setContentModal(nextsoalRes.data.message);
        } else if (nextsoalRes.status === 202) {
          setModalSuccess(true);
          setTitleModal("Yeay!, Akhirnya Tes Selesai");
          setIconModal(
            <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-emerald-100 sm:mx-0 sm:h-10 sm:w-10 text-emerald-500">
              <HiCheckCircle size={24} />
            </div>
          );
          setContentModal("Soal tes ujian sudah berakhir...");
        } else if (nextsoalRes.status > 400) {
          setModalShow(true);
          setTitleModal("Opps, Maaf!");
          setIconModal(
            <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10 text-red-500">
              <HiShieldExclamation size={24} />
            </div>
          );
          setContentModal(nextsoalRes.data.message);
        } else {
          setModalShow(true);
          setTitleModal("Opps, Maaf!");
          setIconModal(
            <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10 text-red-500">
              <HiShieldExclamation size={24} />
            </div>
          );
          setContentModal(
            "Terjadi kesalahan, mohon periksa internet Anda, silahkan reload browser Anda!"
          );
        }
      } else {
        setModalShow(true);
        setTitleModal("Opps, Maaf!");
        setIconModal(
          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10 text-red-500">
            <HiShieldExclamation size={24} />
          </div>
        );
        setContentModal(
          "Terjadi kesalahan, mohon periksa internet Anda, silahkan reload browser Anda!"
        );
      }
    },
    [trigger, data]
  );

  if (isLoading || isMutating || isMutatingInstruction)
    return <Loading pesanLoading="Mohon Tunggu..." />;

  if (error) return <ErrorPage />;

  // if (!data) return <Finish />;

  return (
    <>
      {modalShow && (
        <ModalAll
          title={titleModal}
          icon={iconModal}
          content={contentModal}
          btnRefClose={() => setModalShow(false)}
        />
      )}

      {modalSuccess && (
        <ModalCountdown
          title={titleModal}
          icon={iconModal}
          content={contentModal}
          url={() => setModalSuccess(false)}
        />
      )}

      {!data ? (
        <Finish />
      ) : (
        <>
          {data && data.readInstruction ? (
            <Instruction
              _id={data._id}
              isMutating={isMutating}
              isMutatingInstruction={isMutatingInstruction}
              isLoading={isLoading}
              instruction={data.instruction}
              timer={data}
              skipInstruction={skipInstruction}
            />
          ) : (
            <>
              <div className="lg:px-10 px-2 w-full mb-4 fixed top-20 z-50">
                <div className="flex flex-row items-center bg-white lg:p-4 p-2 shadow-lg rounded-lg">
                  <HiOutlineClock size={32} color="#94a3b8" />
                  <div className="flex-1 ml-2">
                    <p className="font-poppins-semibold text-sm">
                      WAKTU PENGERJAAN SOAL
                    </p>
                    <p className="font-poppins-regular text-xs text-rose-400">
                      Perhatikan waktu Anda dalam mengerjakan soal ini!
                    </p>
                  </div>
                  <div className="flex flex-row items-center justify-center">
                    {data && (
                      <TimerSoal2
                        _id={data._id}
                        isMutating={isMutating}
                        isMutatingInstruction={isMutatingInstruction}
                        isLoading={isLoading}
                        error={error}
                        timer={data.durasi3}
                        durasi={data.durasi}
                        buttonNextRef={buttonNextRef}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="container mx-auto mt-[10rem] lg:mt-44">
                <div className="grid grid-cols-12 grid-flow-col gap-3">
                  <div className="lg:col-start-4 lg:col-span-6 col-start-1 col-span-12 bg-white p-4 rounded-lg shadow-lg">
                    <div className="flex flex-row items-center justify-between">
                      <div className="flex-1">
                        <p className="font-poppins-regular text-sm text-black">
                          Petunjuk Pengerjaan Soal
                        </p>
                        <p className="font-poppins-regular text-xs text-gray-400">
                          Silahkan baca kembali petunjuk pengerjaan soal jika
                          belum paham.
                        </p>
                      </div>
                      <button
                        type="button"
                        ref={btnPetunjuk}
                        onClick={() => setShowPetunjuk(!showPetunjuk)}
                        className="text-white bg-[#f59e0b] hover:bg-[#f59e0b]/90 active::bg-[#f59e0b]/50  focus:outline-none focus:ring-[#f59e0b]/50 font-poppins-medium rounded-lg text-sm px-4 py-2 text-center inline-flex items-center justify-center shadow-md my-8"
                      >
                        <span className="mr-2">
                          <HiOutlineQuestionMarkCircle size={24} />
                        </span>
                        <span>Baca Petunjuk</span>
                      </button>
                    </div>

                    <h3 className="font-poppins-semibold md:text-lg lg:mt-6 mt-4">
                      Kerjakan soal tes dibawah ini!
                    </h3>
                    <h3 className="font-poppins-semibold md:text-lg lg:mt-1 mt-2 mb-4">
                      {data.noUrut}. {data.namaSoal}
                    </h3>

                    {data.questions.length > 0 &&
                      data.questions.map((item, index) => {
                        if (item.tpQuestion === "image") {
                          return (
                            <SoalGambar
                              key={`soal-${index}`}
                              masterId={data._id}
                              questionId={item._id}
                              noQuestion={item.noQuestion}
                              tpQuestion={item.tpQuestion}
                              txtQuestion={item.txtQuestion}
                              questionOpsi={item.questionOpsi}
                              jawabId={item.jawabId}
                              mutate={mutate}
                            />
                          );
                        } else if (item.tpQuestion === "pola") {
                          return (
                            <SoalPola
                              key={`soal-${index}`}
                              masterId={data._id}
                              questionId={item._id}
                              tpQuestion={item.tpQuestion}
                              txtQuestion={item.txtQuestion}
                              questionOpsi={item.questionOpsi}
                              jawabPola={item.jawabPola}
                              mutate={mutate}
                            />
                          );
                        } else {
                          return (
                            <SoalText
                              key={`soal-${index}`}
                              masterId={data._id}
                              questionId={item._id}
                              noQuestion={item.noQuestion}
                              tpQuestion={item.tpQuestion}
                              txtQuestion={item.txtQuestion}
                              columnOpsi={item.columnOpsi}
                              questionOpsi={item.questionOpsi}
                              jawabId={item.jawabId}
                              mutate={mutate}
                            />
                          );
                        }
                      })}

                    <div className="bg-yellow-50 p-4 rounded-lg shadodw mb-2">
                      <h3 className="text-rose-700 text-lg font-poppins-regular mb-2">
                        Perhatian!!!
                      </h3>
                      <p className="font-poppins-regular text-xs text-black">
                        Untuk menyelesaikan soal ini lebih awal, bisa mengklik
                        tombol lanjut dibawah ini. Pastikan Anda telah
                        menyelesaikan semua soal diatas.
                      </p>
                    </div>

                    {data.totalJawab === data.questions.length ||
                    data.questions.length === 1 ? (
                      <button
                        type="button"
                        ref={buttonNextRef}
                        disabled={isMutating}
                        onClick={nextSoal}
                        className="text-white bg-[#2563eb] hover:bg-[#2563eb]/90 active::bg-[#2563eb]/50  focus:outline-none focus:ring-[#2563eb]/50 font-poppins-medium rounded-lg text-sm px-5 py-3 text-center inline-flex items-center justify-center shadow-md w-full my-8"
                      >
                        <span>Lanjutkan</span>
                        <span className="ml-2">
                          <HiArrowRight />
                        </span>
                      </button>
                    ) : (
                      <>
                        <input
                          type="submit"
                          onClick={nextSoal}
                          className="hidden"
                          ref={buttonNextRef}
                        />
                        <div className="text-gray-700 bg-gray-400 font-poppins-medium rounded-lg text-sm px-5 py-3 text-center inline-flex items-center justify-center shadow-md w-full my-8">
                          <span>Lanjutkan</span>
                          <span className="ml-2">
                            <HiArrowRight />
                          </span>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
              {showPetunjuk && (
                <Modal
                  title="Petunjuk Pengerjaan Soal"
                  content={
                    <div
                      className="flex flex-col space-y-5 mt-5 font-pt-serif text-sm text-black article"
                      dangerouslySetInnerHTML={{
                        __html: localStorage.getItem("petunjuk"),
                      }}
                    />
                  }
                  btnRefClose={() => setShowPetunjuk(!setShowPetunjuk)}
                />
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default ResIndex;
