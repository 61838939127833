import React from "react";
import IlusWarning from "../../assets/images/warning.avif";
import { HiOutlineArrowLeft } from "react-icons/hi";
import { useNavigate } from "react-router-dom";

const NotUpdate = () => {
  const navigate = useNavigate();

  return (
    <div className="container mx-auto mt-[5.8rem] lg:mt-24">
      <div className="grid grid-cols-12 grid-flow-col gap-3">
        <div className="lg:col-start-4 lg:col-span-6 col-start-1 col-span-12 bg-white p-4 rounded-lg shadow-lg">
          <h2 className="text-center font-poppins-semibold text-2xl text-red-600">
            Hasil Tes Belum di Update!
          </h2>
          <p className="font-poppins-regular text-center text-sm mb-4 text-black">
            Maaf, hasil tes Anda hanya bisa di lihat H+1 setelah tes
            dilaksanakan!
          </p>
          <img
            className="h-auto max-w-lg mx-auto"
            src={IlusWarning}
            width="280px"
            alt="description"
          />

          <p className="text-center font-poppins-regular text-sm mt-4 lg:mx-20 mx-4">
            Silahkan klik tombol dibawah ini!
          </p>

          <button
            type="button"
            onClick={() => navigate("/carihasil/tesoffline")}
            className="text-white bg-[#059669] hover:bg-[#059669]/90 active::bg-[#059669]/50  focus:outline-none focus:ring-[#059669]/50 font-poppins-medium rounded-lg text-sm px-5 py-3 text-center inline-flex items-center justify-center shadow-md w-full my-8"
          >
            <span className="mr-2">
              <HiOutlineArrowLeft />
            </span>
            <span>Kembali ke Pencarian</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default NotUpdate;
