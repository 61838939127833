import axios from "axios";

export const sendReqGet = async (url) => {
  return await axios.get(url).then((res) => res.data);
};

export const sendRequestPost = async (url, { arg }) => {
  return await axios
    .post(url, arg)
    .then((res) => res)
    .catch((error) => error.response);
};

export const sendRequestGet = async (url) => {
  const token = localStorage.getItem("tokenTes");

  return await axios
    .get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data);
};

export const sendRequestPut = async (url, { arg }) => {
  const token = localStorage.getItem("tokenTes");

  return await axios
    .put(url, arg, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res)
    .catch((error) => error.response);
};
