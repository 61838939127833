import React, { useCallback, useEffect, useState } from "react";
import jwtDecode from "jwt-decode";
import { useNavigate } from "react-router-dom";

import SocialGraduate from "../../assets/images/social-graduate.png";

import useSWRMutation from "swr/mutation";
import { sendRequestPut } from "../../swr/axiosFetch";
import { API_URL_TES } from "../../config";
import {
  HiArrowNarrowRight,
  HiCheckCircle,
  HiShieldExclamation,
} from "react-icons/hi";
import ErrorPage from "../ErrorPage";
import Loading from "../../components/Loading";
import ModalAll from "../../components/Modal/ModalAll";
import ModalCountdown from "../../components/Modal/ModalCountdown";

const Finish = () => {
  const [idPemohon, setIdPemohon] = useState("");
  const navigate = useNavigate();

  const [modalShow, setModalShow] = useState(false);
  const [modalSuccess, setModalSuccess] = useState(false);
  const [titleModal, setTitleModal] = useState("");
  const [iconModal, setIconModal] = useState("");
  const [contentModal, setContentModal] = useState("");

  useEffect(() => {
    // get refresh token
    checkToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkToken = useCallback(() => {
    const token = localStorage.getItem("tokenTes");
    if (token) {
      const decoded = jwtDecode(token);

      setIdPemohon(decoded._id);

      const currentDate = new Date();
      if (decoded.exp * 1000 < currentDate.getTime()) {
        navigate("/");
      }
    } else {
      navigate("/");
    }
  }, [navigate]);

  const { trigger, error, isMutating } = useSWRMutation(
    `${API_URL_TES}/pemohon/hitungnilai`,
    sendRequestPut,
    {
      loadingTimeout: 3000,
      shouldRetryOnError: false,
    }
  );

  const hitungHasil = useCallback(async () => {
    const hitungRes = await trigger({
      kode: "Hitung",
    });

    if (hitungRes.status === 200) {
      localStorage.removeItem("petunjuk");
      localStorage.removeItem("tglBerakhir");
      localStorage.removeItem("tglSelesaiInstruction");
      localStorage.removeItem("tokenTes");

      setModalSuccess(true);
      setTitleModal("Success!");
      setIconModal(
        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-emerald-100 sm:mx-0 sm:h-10 sm:w-10 text-emerald-500">
          <HiCheckCircle size={24} />
        </div>
      );
      setContentModal(hitungRes.data.message);
    } else {
      setModalShow(true);
      setTitleModal("Opps, Maaf!");
      setIconModal(
        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10 text-red-500">
          <HiShieldExclamation size={24} />
        </div>
      );
      setContentModal(
        "Maaf, terjadi kesalahan, silahkan cek hasil tes Anda di menu cari hasil tes!"
      );
    }
  }, [trigger]);

  if (error) return <ErrorPage />;

  return (
    <>
      <div className="container mx-auto mt-[5.8rem] lg:mt-24">
        <div className="grid grid-cols-12 grid-flow-col gap-3">
          <div className="lg:col-start-4 lg:col-span-6 col-start-1 col-span-12 bg-white p-4 rounded-lg shadow-lg mx-4 lg:mx-0">
            {isMutating && (
              <Loading pesanLoading="Mohon tunggu, sedang melakukan hitung hasil" />
            )}
            <h2 className="text-center font-poppins-semibold text-2xl text-emerald-600">
              Selamat Sukses
            </h2>
            <p className="font-poppins-regular text-center text-sm mb-4 text-black">
              Anda sudah menyelesaikan semua soal tes
            </p>
            <img
              className="h-auto max-w-lg mx-auto"
              src={SocialGraduate}
              width="180px"
              alt="description"
            />

            <p className="text-center font-poppins-regular text-sm mt-4 lg:mx-20 mx-4">
              Silahkan Klik tombol dibawah ini untuk melanjutkan hitung hasil
              tes.
            </p>
            <button
              type="button"
              onClick={hitungHasil}
              disabled={isMutating}
              className="text-white bg-[#059669] hover:bg-[#059669]/90 active::bg-[#059669]/50  focus:outline-none focus:ring-[#059669]/50 font-poppins-medium rounded-lg text-sm px-5 py-3 text-center inline-flex items-center justify-center shadow-md w-full my-8"
            >
              <span>Hitung Hasil Tes</span>
              <span className="mr-2">
                <HiArrowNarrowRight />
              </span>
            </button>
          </div>
        </div>
      </div>

      {modalShow && (
        <ModalAll
          title={titleModal}
          icon={iconModal}
          content={contentModal}
          btnRefClose={() => setModalShow(false)}
        />
      )}

      {modalSuccess && (
        <ModalCountdown
          title={titleModal}
          icon={iconModal}
          content={contentModal}
          url={() =>
            navigate(`/detailhasil/${idPemohon}`, {
              replace: true,
            })
          }
        />
      )}
    </>
  );
};

export default Finish;
