import React from "react";

const Policy = () => {
  return (
    <>
      <div className="px-4 py-2 bg-slate-50 border border-slate-100 rounded-lg lg:mx-8 h-36 overflow-y-scroll">
        <ol className="px-4 py-2 list-decimal font-poppins-regular text-xs text-justify">
          <li className="mb-2">
            Dengan ini Pemohon menyatakan bersedia memberikan segala data
            pribadi yg diperlukan dalam pengajuan permohonan ini, membebaskan
            Psikologi SIM Online dari segala macam tuntutan hukum dalam bentuk
            apapun yg berasal dari pihak manapun termasuk dan tidak terbatas
            apabila terjadinya penyalahgunaan dalam bentuk apapun oleh pihak
            manapun terhadap data pribadi Pemohon.
          </li>
          <li>
            Dengan ini Pemohon menyatakan bahwa pelaksanaan Tes Psikologi SIM
            Online benar-benar dilakukan oleh Pemohon sendiri tanpa diwakilkan
            dan/atau dibantu oleh pihak manapun, oleh karenanya Pemohon
            menyatakan bertanggung jawab penuh atas pernyataannya tersebut dan
            membebaskan Psikologi SIM Online dari segala macam tuntutan hukum
            dalam bentuk apapun yg berasal dari pihak manapun apabila dikemudian
            hari diketemukan dan/atau terjadinya pelanggaran hukum.
          </li>
        </ol>
      </div>
    </>
  );
};

export default Policy;
