import React from "react";
import { HiX } from "react-icons/hi";

const ErrorPage = () => {
  return (
    <div className="container mx-auto mt-[5.8rem] lg:mt-24">
      <div className="grid grid-cols-12 grid-flow-col gap-3">
        <div className="lg:col-start-4 lg:col-span-6 col-start-1 col-span-12 bg-white p-4 rounded-lg shadow-lg mx-4 lg:mx-0">
          <div className="flex justify-center mt-4">
            <div className="relative flex h-10 w-10">
              <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
              <span className="relative inline-flex rounded-full h-10 w-10 bg-red-500 justify-center items-center">
                <HiX size={36} color="#FFFFFF" />
              </span>
            </div>
          </div>
          <h2 className="text-center font-poppins-semibold text-2xl text-red-600 mt-4 mb-8">
            Data Belum Ter-Verifikasi
          </h2>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
