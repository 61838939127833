import React from "react";
import QuestTextOpsi from "./QuestTextOpsi";

const SoalText = React.memo(
  ({
    masterId,
    questionId,
    noQuestion,
    txtQuestion,
    jawabId,
    columnOpsi,
    questionOpsi,
    mutate,
  }) => {
    const templateColumn = (column, btnOpsi) => {
      if (column === 2) {
        return (
          <div className="grid grid-cols-2 lg:grid-cols-3 gap-3">{btnOpsi}</div>
        );
      } else if (column === 3) {
        return (
          <div className="grid grid-cols-3 lg:grid-cols-4 gap-3">{btnOpsi}</div>
        );
      } else if (column === 4) {
        return (
          <div className="grid grid-cols-4 lg:grid-cols-6 gap-3">{btnOpsi}</div>
        );
      } else {
        return (
          <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-1">
            {btnOpsi}
          </div>
        );
      }
    };

    return (
      <div className="mb-8">
        <div className="flex flex-row items-center">
          <div className="mr-2">
            <div className="px-3 py-0.5 ml-auto text-2xl font-sans font-bold tracking-wide text-slate-500 bg-slate-50 rounded-full">
              {noQuestion}
            </div>
          </div>
          <div className="flex-1">
            <p className="font-poppins-regular text-lg">{txtQuestion}</p>
          </div>
        </div>
        <div>
          {templateColumn(
            columnOpsi,
            <>
              {questionOpsi.map((item, index) => (
                <QuestTextOpsi
                  key={`opsi-${index}`}
                  masterId={masterId}
                  questionId={questionId}
                  noOpsi={item.noOpsi}
                  txtOpsi={item.txtOpsi}
                  jawabId={jawabId}
                  opsiId={item._id}
                  mutate={mutate}
                />
              ))}
            </>
          )}
        </div>
      </div>
    );
  }
);

export default SoalText;
