import React from "react";
import QuestOpsi from "./QuestOpsi";
import QuestTextOpsi from "./QuestTextOpsi";

const SoalGambar = React.memo(
  ({
    masterId,
    questionId,
    noQuestion,
    txtQuestion,
    jawabId,
    questionOpsi,
    mutate,
  }) => {
    return (
      <div className="mb-8">
        <div className="flex flex-row items-center">
          <div className="mr-2">
            <div className="px-3 py-0.5 ml-auto text-2xl font-sans font-bold tracking-wide text-slate-500 bg-slate-50 rounded-full">
              {noQuestion}
            </div>
          </div>
          <div className="flex-1">
            <figure className="w-full">
              <img src={txtQuestion} alt={noQuestion} />
            </figure>
          </div>
        </div>

        <div className="grid grid-cols-3 lg:grid-cols-5 gap-3 mt-3">
          {questionOpsi.map((item, index) => {
            if (item.tpOpsi === "image") {
              return (
                <QuestOpsi
                  key={`opsi-${index}`}
                  masterId={masterId}
                  questionId={questionId}
                  noOpsi={item.noOpsi}
                  txtOpsi={item.txtOpsi}
                  jawabId={jawabId}
                  opsiId={item._id}
                  mutate={mutate}
                />
              );
            } else {
              return (
                <QuestTextOpsi
                  key={`opsi-${index}`}
                  masterId={masterId}
                  questionId={questionId}
                  noOpsi={item.noOpsi}
                  txtOpsi={item.txtOpsi}
                  jawabId={jawabId}
                  opsiId={item._id}
                  mutate={mutate}
                />
              );
            }
          })}
        </div>
      </div>
    );
  }
);

export default SoalGambar;
