import React from "react";
import { useParams } from "react-router-dom";

import useSWR from "swr";
import { API_URL_TES } from "../../config";

import Loading from "../../components/Loading";

const fetcher = (url) => fetch(url).then((r) => r.json());

const DetailKonseling = () => {
  const { id } = useParams();

  const { data, error, isLoading } = useSWR(
    `${API_URL_TES}/pemohon/konseling/${id}`,
    fetcher,
    {
      loadingTimeout: 3000,
    }
  );

  if (isLoading || error) return <Loading />;

  return (
    <div className="container mx-auto mt-[5.8rem] lg:mt-24">
      <div className="grid grid-cols-12 grid-flow-col gap-3">
        <div className="lg:col-start-4 lg:col-span-6 col-start-1 col-span-12 bg-white p-4 rounded-lg shadow-lg mx-4 lg:mx-0">
          <h2 className="text-center font-poppins-semibold text-2xl mb-4 text-blue-600">
            Detail Konseling
          </h2>
          <div className="p-4 border border-1 border-gray-100 rounded-lg shadow-lg">
            <p className="font-poppins-regular text-center text-sm mb-4 text-black">
              Anda akan dihubungi :
            </p>
            <h3 className="font-poppins-bold h-3 text-center text-lg mb-3 text-black">
              SDR. RYAN
            </h3>
            <h4 className="font-poppins-regular h-4 text-center mb-10 text-gray-500">
              KONSELOR
            </h4>

            <h4 className="font-poppins-semibold h-4 text-center mb-3 text-black">
              {data.tglKonseling}
            </h4>
            <h4 className="font-poppins-semibold h-4 text-center mb-3 text-black">
              {data.jadwalId.jamJadwal}
            </h4>
            <h4 className="font-poppins-regular h-4 text-center mb-3 text-black">
              Dengan Nomor Whatsapp :
            </h4>
            <h4 className="font-poppins-semibold h-4 text-center mb-3 text-black">
              0851 8277 2070
            </h4>

            <div className="p-4 bg-rose-200 text-rose-800 font-poppins-regular text-xs mt-10 rounded-sm">
              * Pastikan nomor whatsapp Anda aktif ketika petugas kami akan
              menghubungi Anda.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailKonseling;
