import React from "react";
import QuestPolaOpsi from "./QuestPolaOpsi";

const SoalPola = React.memo(
  ({ masterId, questionId, txtQuestion, jawabPola, questionOpsi, mutate }) => {
    return (
      <div className="mb-8">
        <div>
          <p className="font-poppins-regular text-lg">{txtQuestion}</p>
        </div>
        <div className="grid lg:grid-cols-10 grid-cols-8 gap-1">
          {questionOpsi.map((item, index) => (
            <QuestPolaOpsi
              key={`opsi-${index}`}
              masterId={masterId}
              questionId={questionId}
              noOpsi={item.noOpsi}
              txtOpsi={item.txtOpsi}
              jawabPola={jawabPola}
              opsiId={item._id}
              mutate={mutate}
            />
          ))}
        </div>
      </div>
    );
  }
);

export default SoalPola;
