import React from "react";
import Swal from "sweetalert2";

import useSWRMutation from "swr/mutation";
import { sendRequestPut } from "../../swr/axiosFetch";
import { API_URL_TES } from "../../config";
import Loading from "./Loading";

const QuestPolaOpsi = React.memo(
  ({ noOpsi, opsiId, txtOpsi, jawabPola, masterId, questionId, mutate }) => {
    const { trigger, isMutating } = useSWRMutation(
      `${API_URL_TES}/pemohon/simpanpola`,
      sendRequestPut,
      {
        loadingTimeout: 3000,
        onSuccess() {
          mutate();
        },
      }
    );

    const { trigger: triggerDrop, isMutating: isMutatingPola } = useSWRMutation(
      `${API_URL_TES}/pemohon/poladrop`,
      sendRequestPut,
      {
        loadingTimeout: 3000,
        onSuccess() {
          mutate();
        },
      }
    );

    const simpan = async (e) => {
      e.preventDefault();
      const jawabRes = await trigger({
        masterId: masterId,
        questionId: questionId,
        noJawab: noOpsi,
        jawabId: opsiId,
      });

      if (jawabRes.status === 200) {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });

        Toast.fire({
          icon: "success",
          title: jawabRes.data.message,
        });
      } else {
        if (jawabRes.status > 400) {
          Swal.fire("Warning!", jawabRes.data.message, "warning");
        } else {
          Swal.fire(
            "Error!",
            "Terjadi kesalahan, mohon periksa internet Anda, silahkan ulangi kembali!",
            "error"
          );
        }
      }
    };

    const checkPola = (opsId) => {
      if (jawabPola.length > 0) {
        const findPola = jawabPola.find((p) => p.jawabId === opsId);

        if (findPola) {
          return true;
        } else {
          return false;
        }

        // return true;
      } else {
        return false;
      }
    };

    const dropPola = async (opsId) => {
      const findPola = jawabPola.find((p) => p.jawabId === opsId);

      const jawabRes = await triggerDrop({
        masterId: masterId,
        questionId: questionId,
        polaId: findPola._id,
      });

      if (jawabRes.status === 200) {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });

        Toast.fire({
          icon: "success",
          title: jawabRes.data.message,
        });
      } else {
        Swal.fire(
          "ERROR!",
          "Terjadi kesalaha, mohon periksa internet Anda, silahkan ulangi kembali!",
          "error"
        );
      }
    };

    if (isMutating || isMutatingPola) return <Loading />;

    return (
      <button
        type="button"
        disabled={isMutating || isMutatingPola}
        onClick={checkPola(opsiId) ? () => dropPola(opsiId) : simpan}
        className={
          checkPola(opsiId)
            ? "text-center bg-white px-2 py-1.5 lg:py-3 shadow-lg w-full rounded-lg mb-2 border-2 border-blue-500"
            : "text-center bg-white px-2 py-1.5 lg:py-3 shadow-lg w-full rounded-lg mb-2 border-2 border-gray-50"
        }
      >
        <span className="font-poppins-regular text-lg">{txtOpsi}</span>
      </button>
    );
  }
);

export default QuestPolaOpsi;
