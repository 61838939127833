import Root from "../components/layout/Root";

// pages
import Home from "../pages/Home";
import CariHasil from "../pages/CariHasil";
import CariOnline from "../pages/CariHasil/CariOnline";
import CariOffline from "../pages/CariHasil/CariOffline";
import HasilOffline from "../pages/DetailHasil/HasilOffline";

import Daftar from "../pages/Daftar";

import ResIndex from "../pages/Responden/ResIndex";

import ResRoot from "../pages/Responden/ResRoot";

import DetailHasil from "../pages/DetailHasil";
import ViewPdfHasilTes from "../pages/DetailHasil/ViewPdf";

import Register from "../pages/Register";
import DetailRegister from "../pages/DetailRegister";

import Konseling from "../pages/Konseling";
import DetailKonseling from "../pages/Konseling/DetailKonseling";

import QrcodeStamp from "../pages/QrcodeStamp";

const Routes = [
  {
    path: "/",
    element: <Root />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "/daftar",
        element: <Daftar />,
      },
      {
        path: "/register",
        element: <Register />,
      },
      {
        path: "/carihasil",
        element: <CariHasil />,
        children: [
          {
            path: "tesonline",
            index: true,
            element: <CariOnline />,
          },
          {
            path: "tesoffline",
            element: <CariOffline />,
          },
        ],
      },
      {
        path: "/konseling",
        element: <Konseling />,
      },
      {
        path: "/konseling/:id",
        element: <DetailKonseling />,
      },
      {
        path: "/responden",
        element: <ResRoot />,
        children: [
          {
            index: true,
            element: <ResIndex />,
          },
        ],
      },
      {
        path: "/detailregister/:id",
        element: <DetailRegister />,
      },
      {
        path: "/detailhasil/:id",
        element: <DetailHasil />,
      },
      {
        path: "/detailhasiloffline/:id",
        element: <HasilOffline />,
      },
      {
        path: "/hasiltespdf/:id",
        element: <ViewPdfHasilTes />,
      },
      {
        path: "/qrcode/:id",
        element: <QrcodeStamp />,
      },
    ],
  },
];

export default Routes;
